import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import { snakeCase } from "snake-case";

import Context from "./context";

mapboxgl.accessToken = `${process.env.GATSBY_MAPBOX_ACCESS_TOKEN}`;

const ServicesMap = () => {
  const { data } = useContext(Context);
  const intl = useIntl();
  const [params] = useState([
    "gas_station",
    "restaurant",
    "gym",
    "tourist_attraction",
    "movie_theater",
  ]);
  const [features, setFeatures] = useState([]);
  const [customFeatures, setCustomFeatures] = useState([]);

  useEffect(() => {
    data.allContentfulPointDinteret.nodes.forEach((node) => {
      setCustomFeatures((customFeatures) => [
        ...customFeatures,
        {
          id: node.place_id,
          name: node.title,
          vicinity: node.vicinity,
          city: node.hotel[0].slug,
          url: node.url,
          types: ["custom_poi", snakeCase(node.category)],
          geometry: {
            location: {
              lat: node.lat,
              lng: node.lng,
            },
          },
        },
      ]);
    });

    params.forEach((feature) => {
      axios
        .get(
          `https://ace-api.ultro.dev/place/nearbysearch?location=${data.contentfulHotel.lat},${data.contentfulHotel.lon}&radius=${data.contentfulHotel.radius}&type=${feature}`
        )
        .then(function (response) {
          setFeatures((features) => [...features, response.data.results]);
        })
        .catch(function (error) {
          console.error(error);
        });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function Poi() {
    let totalRestaurant = 0;
    let totalGym = 0;
    let totalGasStation = 0;
    let totalEntertainment = 0;
    const poiOfCity = [];

    if (features.length === params.length) {
      // On ne garde que les customs POIs de la ville concernée
      customFeatures.forEach((element) => {
        if (element.city === data.contentfulHotel.slug) {
          poiOfCity.push(element);
        }
      });

      // On ajoute la liste des Pois custom pour les afficher sur la carte
      features.push(poiOfCity);
      // Construction de la carte
      const map = new mapboxgl.Map({
        container: "ServicesMap",
        style: `${process.env.GATSBY_MAPBOX_STYLE}`,
        center: [data.contentfulHotel.lon, data.contentfulHotel.lat],
        zoom: 14,
      });

      // On paramètre les options de la carte
      map.scrollZoom.disable();
      map.addControl(new mapboxgl.NavigationControl());
      map.addControl(new mapboxgl.FullscreenControl());

      // On créer le marker de l'hôtel
      const el = document.createElement("div");
      el.className = `map-marker-${
        data.contentfulHotel.spirit.slug === "travel"
          ? "travel"
          : "simplement-bien"
      }`;

      // On ajoute le marker à la map
      new mapboxgl.Marker(el)
        .setLngLat([data.contentfulHotel.lon, data.contentfulHotel.lat])
        .addTo(map);

      // On parcours tous les types de features
      features.forEach((featureType) => {
        featureType.forEach((feature) => {
          // On créer un marker pour chaque feature
          const el = document.createElement("div");
          el.className += "map-marker";

          if (
            feature.url &&
            feature.types.includes("restaurant") &&
            data.contentfulHotel.slug === feature.city
          ) {
            el.className +=
              " map-marker-restaurant map-marker-restaurant-partner";
            totalRestaurant++;
          }
          if (
            feature.types.includes("food") &&
            feature.types.includes("restaurant")
          ) {
            totalRestaurant++;
            el.className += " map-marker-restaurant";
          }
          if (
            feature.types.includes("movie_theater") ||
            feature.types.includes("tourist_attraction")
          ) {
            totalEntertainment++;
            el.className += " map-marker-entertainment";
          }
          if (feature.types.includes("gas_station")) {
            totalGasStation++;
            el.className += " map-marker-gas-station";
          }
          if (feature.types.includes("gym")) {
            totalGym++;
            el.className += " map-marker-gym";
          }

          // On ajoute le marker à la map
          new mapboxgl.Marker(el)
            .setLngLat([
              feature.geometry.location.lng,
              feature.geometry.location.lat,
            ])
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }) // add popups
                .setHTML(
                  `
                    <h3>${feature.name}</h3>
                    <p${feature.url ? `` : ` class="mb-0"`}>${
                    feature.vicinity
                  }</p$>
                    ${
                      feature.url
                        ? `
                        <p class="mb-0">
                          <a href=${
                            feature.url
                          } target="_blank" rel="noreferrer">${intl.formatMessage(
                            {
                              id: "more",
                            }
                          )}</a>
                        </p>
                        <svg width="64" height="43" viewBox="0 0 64 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M39.0942 13.7589C39.2711 17.3677 42.454 20.8428 46.5211 20.6646C51.7818 20.4418 52.7986 16.5212 52.7986 16.1648C52.7986 16.0756 52.7544 16.0311 52.666 16.0311L52.3123 15.8974C52.1797 15.8529 52.0471 15.942 51.9586 16.1202C51.4282 17.1449 50.5882 17.9469 49.483 18.2587C47.0516 18.9716 44.3991 17.1895 43.5592 14.1599C42.8077 11.5758 43.6034 8.85806 45.4159 7.74424C46.7421 6.98684 48.4662 7.43237 49.262 8.76896C50.0577 10.1055 49.6599 11.8431 48.3336 12.6451C47.4053 13.2243 45.9464 13.1797 44.9738 12.2441C44.6644 11.9768 44.6644 12.5114 44.6644 12.6005C44.6644 12.7342 44.7086 13.2243 45.1949 13.7589C46.5211 15.1846 48.6873 15.4965 50.4114 14.4717C52.4007 13.2688 53.0638 10.6402 51.826 8.59075C51.5608 8.14522 49.9693 5.78391 45.9464 5.91757C43.1171 6.05123 38.8732 8.59075 39.0942 13.7589Z" fill="#171930"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M38.7402 17.5021L38.4308 17.2793C38.2982 17.1902 38.1655 17.2348 38.0329 17.413C37.2814 18.3041 36.2646 18.8387 35.1152 18.8833C32.5954 18.9724 30.4292 16.5219 30.2966 13.4032C30.164 10.24 32.1091 7.61134 34.6289 7.52224C35.8668 7.47768 37.0161 8.05687 37.9003 8.99249C37.9445 9.03704 37.9887 8.99249 38.0771 8.94793L38.3424 8.72517C38.3866 8.68061 38.4308 8.63606 38.4308 8.54696C38.4308 8.10143 36.6183 5.78467 32.949 5.96288C28.8819 6.14109 25.7874 9.57167 25.9642 13.6706C26.1411 17.7249 29.5451 20.8881 33.6122 20.7099C37.5908 20.5317 38.8287 17.814 38.8287 17.6358C38.8287 17.5912 38.7845 17.5467 38.7402 17.5021Z" fill="#F9B000"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M27.424 20.3972C27.424 20.3972 27.1588 20.0853 27.0704 20.0408C26.142 19.0606 24.1969 16.7884 24.1969 12.9569C24.1969 8.85799 21.7655 6.0957 17.6984 6.0957C13.4102 6.0957 12.1724 8.81344 12.1724 8.99165C12.1724 9.08075 12.2166 9.12531 12.2608 9.16986L12.5703 9.39262C12.6145 9.43718 12.6587 9.43718 12.7471 9.43718C13.5429 8.41246 14.6922 7.78872 15.9301 7.74416C18.2289 7.69961 19.7761 9.34807 20.0414 12.0658C19.9972 12.0658 19.9972 12.0658 19.953 12.0213C19.7761 11.8876 18.8478 10.8183 16.3279 10.8183C13.4544 10.8183 11.1556 13.1351 11.1998 15.8528C11.2441 18.6151 13.6313 20.7536 16.5932 20.7536C19.3783 20.7536 20.5719 18.927 20.5719 18.8824C20.5719 18.7933 20.5719 18.7488 20.5277 18.7042L20.2624 18.4369C20.1298 18.3478 19.9972 18.3923 19.8645 18.4814C17.1237 20.5754 14.8691 18.526 14.8691 16.2092C14.8691 13.8925 16.77 12.8232 17.7426 12.8232C19.5993 12.8232 20.0414 13.937 20.2182 14.8726C21.1908 20.4418 25.6999 21.1101 27.203 20.6645C27.5567 20.5309 27.424 20.3972 27.424 20.3972Z" fill="#171930"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.6602 29.0854C20.6602 27.0359 22.6495 25.4766 25.0367 25.4766C27.3797 25.4766 29.0596 26.8132 29.0596 28.818C29.0596 30.8229 27.0703 32.3823 24.6388 32.3823C22.3843 32.3823 20.6602 31.0457 20.6602 29.0854ZM27.2471 29.1745C27.2471 27.2587 26.1861 26.1894 24.7273 26.1894C23.401 26.1894 22.4285 27.0359 22.4285 28.6844C22.4285 30.5111 23.4452 31.5803 24.9483 31.5803C27.1587 31.5358 27.2471 29.5754 27.2471 29.1745Z" fill="#171930"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M33.8323 26.4117C35.1144 26.4117 35.6006 26.278 35.9985 27.3473C36.0427 27.481 36.0427 27.481 36.1311 27.481H36.3522C36.4406 27.481 36.4406 27.481 36.4406 27.3919V25.7434C36.4406 25.6543 36.4406 25.6543 36.3522 25.6543H29.5884C29.5 25.6543 29.5 25.6989 29.5 25.7434V27.3028C29.5 27.4364 29.5 27.481 29.5884 27.481H29.8537C29.8979 27.481 29.9421 27.4364 29.9421 27.3473C30.2073 26.5454 30.6936 26.4117 31.4451 26.4117H32.1525V32.2481H33.8766V26.4117H33.8323Z" fill="#171930"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M43.737 30.6439H43.516C43.4718 30.6439 43.4275 30.733 43.4275 30.8221C43.1623 31.5795 42.7644 31.4904 41.8803 31.4904H40.1562V29.1736H42.0129C42.3224 29.1736 42.8528 29.3964 42.9413 29.9756C42.9413 30.0647 42.9855 30.1092 43.0739 30.1092H43.2949C43.3391 30.1092 43.3833 30.0647 43.3833 30.0201V27.837C43.3833 27.7925 43.3391 27.7479 43.3391 27.7479H43.0739C43.0297 27.7479 42.9855 27.837 42.9855 27.8816C42.897 28.4162 42.4992 28.4608 41.9687 28.4608H40.1562V26.4113H42.0129C42.4992 26.4113 43.1623 26.3222 43.3391 26.9014C43.3833 26.9905 43.3833 26.9905 43.4275 26.9905H43.6486C43.6928 26.9905 43.737 26.946 43.737 26.946V25.6539C43.737 25.6094 43.6928 25.6094 43.6486 25.6094H37.4153C37.3711 25.6094 37.3711 25.6539 37.3711 25.6985V25.9212C37.3711 26.0104 37.3711 26.0104 37.4153 26.0104H37.6363C38.2995 26.0549 38.3437 26.1886 38.3437 27.837V32.2478H43.6928C43.7812 32.2478 43.7812 32.2032 43.7812 32.1587V30.733C43.8254 30.6439 43.8254 30.6439 43.737 30.6439Z" fill="#171930"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M51.0326 30.3769H50.9C50.8116 30.3769 50.8116 30.4215 50.7232 30.5551C50.4137 31.4016 49.9274 31.4907 48.6454 31.4907H47.4076V25.6543H44.7551C44.7109 25.6543 44.7109 25.6989 44.7109 25.7434V25.9662C44.7109 26.0553 44.7109 26.0553 44.7551 26.0553H44.9762C45.6393 26.0998 45.6835 26.2335 45.6835 27.8819V32.2927H50.9884C51.121 32.2927 51.1653 32.2481 51.1653 32.1145V30.5551C51.1653 30.4215 51.121 30.3769 51.0326 30.3769Z" fill="#171930"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9481 25.6543C16.9039 25.6543 16.9039 25.6989 16.9039 25.7434V25.9662C16.9039 26.0553 16.9039 26.0553 16.9481 26.0553H17.1691C17.8323 26.0998 17.8765 26.2335 17.8765 27.8819V28.4166H14.2514V25.6989H12.5273V32.3372H14.2514V29.2631H17.8765V32.3372H19.6006V25.6543H16.9481Z" fill="#171930"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M24.1508 23.6934H25.7423L26.8033 25.2082H26.3612L24.8139 24.2725L23.4877 25.2082H23.0898L24.1508 23.6934Z" fill="#171930"/>
                          <path d="M27.4455 36.3357C27.2201 36.4952 27.1667 36.8073 27.3263 37.0327C27.4858 37.2581 27.7979 37.3114 28.0233 37.1519L27.4455 36.3357ZM33.0539 38.3302L32.5918 38.5214L32.7906 39.0017L33.2628 38.7844L33.0539 38.3302ZM35.8237 39.4666C35.9507 39.7118 36.2524 39.8077 36.4976 39.6807C36.7428 39.5538 36.8387 39.252 36.7117 39.0068L35.8237 39.4666ZM28.0233 37.1519C28.6603 36.701 29.5565 36.412 30.4001 36.5438C31.2073 36.6699 32.0418 37.1921 32.5918 38.5214L33.5159 38.139C32.8469 36.5223 31.742 35.7413 30.5544 35.5558C29.4032 35.3759 28.2491 35.7668 27.4455 36.3357L28.0233 37.1519ZM33.0539 38.3302C33.2628 38.7844 33.2626 38.7845 33.2625 38.7846C33.2624 38.7846 33.2622 38.7847 33.2621 38.7848C33.262 38.7849 33.2618 38.7849 33.2617 38.785C33.2614 38.7851 33.2613 38.7852 33.2612 38.7852C33.2612 38.7852 33.2617 38.785 33.2627 38.7845C33.2647 38.7837 33.2686 38.7819 33.2745 38.7794C33.2861 38.7745 33.3052 38.7666 33.3309 38.7567C33.3825 38.7368 33.4598 38.7091 33.5565 38.6808C33.752 38.6234 34.0148 38.5669 34.2972 38.561C34.843 38.5496 35.4363 38.7185 35.8237 39.4666L36.7117 39.0068C36.1017 37.8287 35.0881 37.5443 34.2763 37.5612C33.8799 37.5695 33.5263 37.6475 33.2751 37.7212C33.1483 37.7583 33.0447 37.7953 32.9712 37.8236C32.9343 37.8378 32.9048 37.85 32.8835 37.859C32.8729 37.8635 32.8643 37.8673 32.8578 37.8702C32.8546 37.8716 32.8518 37.8728 32.8497 37.8738C32.8486 37.8743 32.8477 37.8747 32.8469 37.8751C32.8465 37.8753 32.8461 37.8754 32.8458 37.8756C32.8456 37.8756 32.8454 37.8758 32.8453 37.8758C32.8451 37.8759 32.8449 37.876 33.0539 38.3302Z" fill="#F9B000"/>
                        </svg>
                      `
                        : ``
                    }
                  `
                )
            )
            .addTo(map);

          // On ajoute un événement pour recentrer la map sur le point
          el.addEventListener("click", () => {
            const coords = [
              feature.geometry.location.lng,
              feature.geometry.location.lat,
            ];
            map.flyTo({
              center: coords,
              speed: 0.2,
            });
          });
        });
      });
    }

    return (
      <div className="tabs tabs-filters mb-4 my-md-8">
        <ul className="tabs-list">
          {totalRestaurant > 0 ? (
            <li className="tabs-list-item" id="restaurants">
              <button
                onClick={() => {
                  filter("map-marker-restaurant");
                  active("restaurants");
                }}
              >
                {intl.formatMessage({ id: "map.restaurants" })}
              </button>
            </li>
          ) : (
            ""
          )}
          {totalGasStation > 0 ? (
            <li className="tabs-list-item" id="stations">
              <button
                onClick={() => {
                  filter("map-marker-gas-station");
                  active("stations");
                }}
              >
                {intl.formatMessage({ id: "map.stations" })}
              </button>
            </li>
          ) : (
            ""
          )}
          {totalGym > 0 ? (
            <li className="tabs-list-item" id="gym">
              <button
                onClick={() => {
                  filter("map-marker-gym");
                  active("gym");
                }}
              >
                {intl.formatMessage({ id: "map.gym" })}
              </button>
            </li>
          ) : (
            ""
          )}
          {totalEntertainment > 0 ? (
            <li className="tabs-list-item" id="entertainment">
              <button
                onClick={() => {
                  filter("map-marker-entertainment");
                  active("entertainment");
                }}
              >
                {intl.formatMessage({ id: "map.entertainment" })}
              </button>
            </li>
          ) : (
            ""
          )}
          <li className="tabs-list-item">
            <button
              onClick={() => {
                reset();
              }}
            >
              {intl.formatMessage({ id: "map.reset" })}
            </button>
          </li>
        </ul>
      </div>
    );
  }

  /**
   * Masque les points qui ne correspondent pas au type du param
   * @param param
   */
  function filter(param) {
    Array.from(document.getElementsByClassName("map-marker")).forEach(
      (place) => {
        place.style.visibility = "visible";
      }
    );
    const places_visible = document.getElementsByClassName(param);
    const places_hidden = document.getElementsByClassName("map-marker");
    const difference = Array.from(places_visible)
      .filter((x) => !Array.from(places_hidden).includes(x))
      .concat(
        Array.from(places_hidden).filter(
          (x) => !Array.from(places_visible).includes(x)
        )
      );
    Array.from(difference).forEach((place) => {
      place.style.visibility = "hidden";
    });
  }

  /**
   * Ajoute la class active sur le filtre sélectionné
   * @param item
   */
  function active(item) {
    Array.from(document.getElementsByClassName("tabs-list-item")).forEach(
      (element) => {
        element.classList.remove("active");
      }
    );
    document.getElementById(item).className += " active";
  }

  function reset() {
    Array.from(document.getElementsByClassName("map-marker")).forEach(
      (place) => {
        place.style.visibility = "visible";
      }
    );
  }

  return (
    <>
      <Poi />
      <div className="map">
        <div className="map-content" id="ServicesMap"></div>
      </div>
    </>
  );
};

export default ServicesMap;
